import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

import { BottomSheet } from 'components/design-system/BottomSheet';
import { Button } from 'components/design-system/Button';
import { AnyQuestionsHelper } from 'components/listing_detail/AnyQuestionsHelper';
import { intercomHideLauncher } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';
import { PurchaseRequestOffer } from 'types';

import { Countdown } from './Countdown';

export type CheckoutBottomSheetProps = {
  requestOffer: PurchaseRequestOffer;
  onClose?: () => void;
};

export const CheckoutBottomSheet: FC<CheckoutBottomSheetProps> = ({ requestOffer, onClose }) => {
  const { push } = useRouter();

  useEffect(() => {
    intercomHideLauncher();
  }, []);

  const onCheckoutWatch = () => {
    onClose?.();
    push(pageRoutes.listingCheckout(requestOffer.listing.id));
  };

  const isExpired = Date.now() > new Date(requestOffer.expiration).getTime();

  return (
    <BottomSheet className='pb-4 d-flex align-items-center flex-column' canClose={false}>
      <Button
        textClassname='w-100 riforma-medium px-3 d-flex justify-content-between'
        className='w-100 mb-3 py-3 border-radius-24'
        onClick={onCheckoutWatch}
      >
        <span className={isExpired ? 'text-center w-100' : ''}>Checkout</span>
        {!isExpired && <Countdown expiration={requestOffer.expiration} className='opacity-60' />}
      </Button>
      <AnyQuestionsHelper />
    </BottomSheet>
  );
};
