import { FC } from 'react';
import classNames from 'classnames';

import { COLORS } from 'consts';

type RequestsTabProps = {
  isSelected: boolean;
  title: 'Unworn' | 'Pre-owned';
  count: number;
  onClick: () => void;
};

export const RequestsTab: FC<RequestsTabProps> = ({ isSelected, count, title, onClick }) => (
  <div
    role='button'
    onClick={onClick}
    style={{ borderBottom: isSelected ? `1px solid ${COLORS.success}` : 'none' }}
    className={classNames('w-50 text-center py-3 riforma-regular', { 'opacity-40': !isSelected })}
  >
    <span className='pe-2'>{title}</span>
    <span className={classNames({ 'text-success': isSelected })}>{count}</span>
  </div>
);
