import { FC, ReactNode } from 'react';

import { getCardBrandIcon } from 'components/checkout/getCardBrandIcon';
import { PaymentMethod } from 'types';

type CardPaymentProps = {
  paymentMethod: PaymentMethod;
  colWidths: [number, number, number];
  isSelectedPaymentMethod: (paymentMethod: PaymentMethod) => boolean;
  checkIcon: ReactNode;
};

export const CardPayment: FC<CardPaymentProps> = ({ paymentMethod, colWidths, isSelectedPaymentMethod, checkIcon }) => {
  const CardIcon = getCardBrandIcon(paymentMethod.details?.brand);

  return (
    <div className='row g-0 w-100 align-items-center fs-12px'>
      <div className={`col-${colWidths[0]} d-inline-flex`}>
        <div
          className='rounded-1'
          style={{
            backgroundColor: isSelectedPaymentMethod(paymentMethod) ? '#eef3ef' : '#ebeae7',
            padding: '0.75rem'
          }}
        >
          <CardIcon
            className={isSelectedPaymentMethod(paymentMethod) ? 'text-success' : 'text-secondary'}
            size='1.5em'
          />
        </div>
      </div>
      <div className={`col-${colWidths[1]}`}>
        <div className='text-capitalize'>{paymentMethod?.details?.brand}</div>
        <div className='riforma-regular opacity-60'>
          {paymentMethod?.details?.lastFour} - {String(paymentMethod?.details?.expMonth).padStart(2, '0')}/
          {paymentMethod?.details?.expYear}
        </div>
      </div>
      {isSelectedPaymentMethod(paymentMethod) && checkIcon}
    </div>
  );
};
