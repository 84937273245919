import { FC } from 'react';
import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import classNames from 'classnames';

import styles from 'styles/PurchaseRequest.module.scss';

type SelectionItemProps = {
  onClick: () => void;
  title: string;
  description: string;
  isSelected?: boolean;
};

export const SelectionItem: FC<SelectionItemProps> = ({ description, onClick, title, isSelected }) => (
  <div
    role='button'
    onClick={onClick}
    className={classNames('rounded-2 border-primary12 border p-4 mt-3', styles.selectionItem, {
      'border-lightPrimaryText': isSelected
    })}
  >
    <div className='d-flex justify-content-between align-items-center text-success mb-3'>
      <span>{title}</span>
      <FaArrowRight />
    </div>
    <div className='opacity-60 riforma-regular fs-14px'>{description}</div>
  </div>
);
