import { FC } from 'react';

import { Link } from 'components/common/Link';
import { PricePill } from 'components/common/PricePill';
import { SlideoutCommonProps, SlideoutTitle } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { PurchaseRequest } from 'types';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestConversationSlideout } from './RequestConversationSlideout';
import { SelectModelSlideout } from './SelectModelSlideout';

type RequestSubmittedSlideoutProps = {
  request: PurchaseRequest;
} & SlideoutCommonProps;

export const RequestSubmittedSlideout: FC<RequestSubmittedSlideoutProps> = ({ request, ...props }) => {
  const { closeModal, openModal } = useModal();
  const { price = 0, resetRequest } = usePurchaseRequest();

  const onMakeAnotherRequest = () => {
    resetRequest();
    openModal(<SelectModelSlideout closeModal={closeModal} />);
  };

  const onOpenDetails = () => openModal(<RequestConversationSlideout request={request} closeModal={closeModal} />);

  return (
    <PurchaseRequestSlideout
      submit={{
        title: 'View request details',
        onClick: onOpenDetails
      }}
      cancel={{ title: 'Request another model', onClick: onMakeAnotherRequest }}
      {...props}
      onClickPrevious={undefined}
    >
      <div className='d-flex flex-column w-100 align-items-center'>
        <PricePill isDark={false} price={price} />
        <div className='mt-4' />
        <SlideoutTitle title='Request submitted' />
        <p className='opacity-60 riforma-regular px-3 mt-4 mb-4 text-center'>
          Your purchase request has been sent to our seller network. You can view responses to your request in the{' '}
          <Link onClick={() => props.closeModal?.()} href='/requests' className='riforma-medium'>
            “Requests”
          </Link>{' '}
          tab of your profile.
        </p>
      </div>
    </PurchaseRequestSlideout>
  );
};
