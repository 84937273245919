import { FC, Fragment, useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';

import { ContentLoader } from 'components/design-system/ContentLoader';
import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { useBezelUser } from 'context/bezelUser';
import { clientApiGet } from 'lib/api_helper';
import { ListingStatus, PurchaseRequest, PurchaseRequestOffer } from 'types';

import { ActionsBottomSheet } from './ActionsBottomSheet';
import { BuyerMessage } from './BuyerMessage';
import { CheckoutBottomSheet } from './CheckoutBottomSheet';
import { InfoSection } from './InfoSection';
import { RequestOffer } from './RequestOffer';
import { StatusMessage } from './StatusMessage';

import { PurchaseRequestSlideout } from '../PurchaseRequestSlideout';

type RequestConversationSlideoutProps = {
  request: PurchaseRequest;
  refreshRequests?: () => void;
  canGoBack?: boolean;
  requestOffer?: PurchaseRequestOffer;
} & SlideoutCommonProps;

export const RequestConversationSlideout: FC<RequestConversationSlideoutProps> = ({
  request,
  requestOffer,
  refreshRequests,
  canGoBack,
  ...props
}) => {
  const { user } = useBezelUser();
  const isBuyer = typeof request.buyerProfile.id === 'number' && request.buyerProfile.id === user?.buyerProfile?.id;
  const isLive = request.status === 'LIVE';
  const [isOptionsSheetVisible, setIsOptionsSheetVisible] = useState(false);

  const { data: requestOffers = [], isLoading } = useSWR<PurchaseRequestOffer[]>(
    request && request.id && !requestOffer
      ? `/api/purchaseRequests/requestOffer?purchaseRequest[]=${request.id}&expand[]=listing.model`
      : null,
    clientApiGet
  );

  const requestOffersData = requestOffer ? [requestOffer] : requestOffers;
  const toggleActionsDrawer = () => setIsOptionsSheetVisible(prev => !prev);
  const acceptedOffer = requestOffersData.find(({ id }) => request.acceptedOffer?.id === id);

  return (
    <PurchaseRequestSlideout
      {...props}
      modelFromProps={request.model}
      extraTitleComponent={<InfoSection request={request} onOptionsClick={toggleActionsDrawer} />}
      {...(canGoBack ? {} : { onClickPrevious: undefined })}
    >
      <div className='d-flex flex-column w-100 align-items-center pb-6'>
        {request.status === 'EXPIRED' && isBuyer ? (
          <BuyerMessage isBuyer title='EXPIRED' price={request.requestedPriceCents} status='EXPIRED' />
        ) : (
          <BuyerMessage
            title={isBuyer ? 'YOUR REQUEST' : 'BUYER REQUEST'}
            isBuyer={isBuyer}
            price={request.requestedPriceCents}
          />
        )}
        {isLoading && (
          <div className={classNames('d-flex w-100 mt-3', { 'justify-content-end': !isBuyer })}>
            <ContentLoader className='w-auto' borderRadius={24} width={272} height={250} />
          </div>
        )}
        {requestOffersData.length === 0 && !isLoading && isLive && isBuyer && <StatusMessage type='waitingForOffers' />}
        {requestOffersData.map((offer, index) => (
          <Fragment key={offer.id + offer.offerPriceCents}>
            <RequestOffer isBuyer={isBuyer} offer={offer} index={index + 1} />
            {offer.status === 'ACCEPTED' && <BuyerMessage isBuyer={isBuyer} status='ACCEPTED' />}
            {offer.status === 'REJECTED' && <BuyerMessage isBuyer={isBuyer} status='REJECTED' />}
            {offer.status === 'ACCEPTED' && offer.listing.status === ListingStatus.Ordered && (
              <BuyerMessage isBuyer={isBuyer} status='ORDERED' />
            )}
            {offer.status === 'PENDING_REVIEW' && !isBuyer && <StatusMessage type='sellerPendingReview' />}
            {offer.status === 'AWAITING_RESPONSE' && !isBuyer && <StatusMessage type='sellerAwaitingResponse' />}
            {request.status === 'PURCHASED' &&
              isBuyer &&
              offer.status === 'ACCEPTED' &&
              offer.listing.status === ListingStatus.Published && (
                <div className='mb-7'>
                  <CheckoutBottomSheet requestOffer={offer} onClose={props.closeModal} />
                </div>
              )}
          </Fragment>
        ))}
        {!isLoading && request.status === 'CLOSED' && <StatusMessage type='closed' />}
        {!isLoading && request.status === 'EXPIRED' && <StatusMessage type='expired' />}
        {!isLoading &&
          request.status === 'PURCHASED' &&
          acceptedOffer &&
          acceptedOffer.listing?.status !== ListingStatus.Ordered &&
          !isBuyer && <StatusMessage type='waitingForCheckout' />}
        {isOptionsSheetVisible && (
          <ActionsBottomSheet
            canCloseRequest={isLive && isBuyer}
            refreshRequests={refreshRequests}
            request={request}
            onClose={() => setIsOptionsSheetVisible(false)}
          />
        )}
      </div>
    </PurchaseRequestSlideout>
  );
};
