import { FC } from 'react';

import { ContentLoader } from 'components/design-system/ContentLoader';

const MobileRow = () => (
  <div className='m-0 d-flex justify-content-between mb-1'>
    {[1, 2].map(id => (
      <ContentLoader key={id} className='w-49' borderRadius={20} height={260} />
    ))}
  </div>
);

const MobileLoader = () => (
  <>
    <MobileRow />
    <MobileRow />
  </>
);

const DesktopRow = () => (
  <div className='m-0 row'>
    {[1, 2, 3].map(id => (
      <ContentLoader key={id} className='flex-1 p-2' borderRadius={20} height={300} />
    ))}
  </div>
);

const DesktopLoader = () => (
  <>
    <DesktopRow />
    <DesktopRow />
  </>
);

type ModelSelectorLoaderProps = {
  isTabletOrMobile: boolean;
  itemsPerRow?: number;
};
export const ModelSelectorLoader: FC<ModelSelectorLoaderProps> = ({ isTabletOrMobile, itemsPerRow = 3 }) =>
  isTabletOrMobile || itemsPerRow === 2 ? <MobileLoader /> : <DesktopLoader />;
