import { Model, PurchaseRequest } from 'types';

const range = (start: number, stop: number) => Array.from({ length: stop - start }, (_, i) => start + i);

export const getModelProductionYears = (model?: Model, purchaseRequest?: PurchaseRequest) => {
  const releaseYear = purchaseRequest?.yearRestriction || model?.releaseYear || new Date().getFullYear();
  const discontinuationYear = model?.discontinuationYear ? model.discontinuationYear + 1 : new Date().getFullYear() + 1;

  return range(releaseYear, discontinuationYear);
};
