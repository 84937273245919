import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';

import { Button } from 'components/design-system/Button';
import { intercomShowNewMessage } from 'lib/intercom';

export const ModelSelectorEmptyStateTestIds = {
  container: 'model-selector-empty-state'
};

export const ModelSelectorEmptyState = () => (
  <div data-testid={ModelSelectorEmptyStateTestIds.container} className='riforma-regular text-center my-5'>
    <div className='white-space-break fs-24px line-height-32px mb-4'>{'That model isn’t in\n our database'}</div>
    <div className='white-space-break fs-14px line-height-20px mb-5 opacity-60'>
      {'In order to list a watch, we have to\nsupport it in our database first'}
    </div>
    <Button className='mx-auto p-3 px-4' variant='secondary' onClick={intercomShowNewMessage}>
      <div className='d-flex align-items-center'>
        <div className='me-5'>Request a new model</div>
        <HiArrowNarrowRight />
      </div>
    </Button>
  </div>
);
