import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Listing, StyledComponent } from 'types';
import { formatPrice } from 'utils/text-helpers';

interface YourWatchProps extends StyledComponent, PropsWithChildren {
  listing: Listing;
  showPrice?: boolean;
  showReleaseYearIfNoManufactureYear?: boolean;
  isDark?: boolean;
  hideImageBorder?: boolean;
}

export const YourWatch: React.FC<YourWatchProps> = ({
  listing,
  style,
  className = 'fs-14px border-sm-top my-1 mb-sm-2 mt-sm-4 pt-sm-3 pb-sm-1 py-1',
  showPrice = false,
  showReleaseYearIfNoManufactureYear = true,
  children,
  isDark,
  hideImageBorder
}) => {
  let year = listing.manufactureYear;

  if (!year && showReleaseYearIfNoManufactureYear) {
    year = listing.model.releaseYear;
  }

  const condition = listing.condition;
  const summaryParts = [];

  if (year) {
    summaryParts.push(year);
  }
  if (condition) {
    summaryParts.push(condition);
  }

  const titleParts = [listing.model.brand.displayName, listing.model.displayName, listing.model.referenceNumber];
  const title = titleParts.join(' ').substring(0, 80);

  const listingImageUrl = listing.images.find(i => i.type === 'FRONT')?.image.url;
  const imageUrl = listingImageUrl || listing.model.images[0]?.url || 'model_placeholder.png';

  return (
    <div
      className={classNames('d-flex justify-content-between align-items-center fw-bold text-primary', className)}
      style={style}
    >
      <div className='d-flex flex-column gap-1 fw-light riforma-regular'>
        <div className={isDark ? 'text-light' : 'text-primary'}>{title}</div>
        {/* TODO refactor this logic */}
        {children}
        {showPrice && !children && (
          <div className='mt-2 riforma-medium fs-12px letter-spacing-08'>
            <span className='text-secondary mr-1'>WIRE PRICE</span>
            <span className='text-success'>{formatPrice(listing.priceCents)}</span>
          </div>
        )}
        {!showPrice && !children && <div className='text-secondary'>{summaryParts.join(' / ')}</div>}
      </div>
      <div className='ps-5'>
        <CloudinaryImage
          priority
          alt='Watch Image'
          className={classNames('rounded-2 gallery-image', { border: !isDark && !hideImageBorder })}
          width={80}
          height={80}
          src={imageUrl}
          style={{ objectFit: 'contain' }}
        />
      </div>
    </div>
  );
};
