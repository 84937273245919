import { useEffect } from 'react';

import { intercomHideLauncher, intercomShowLauncher } from 'lib/intercom';

export const useHideIntercom = () => {
  // hide intercom from current component, but make it visible after component unmounts
  useEffect(() => {
    intercomHideLauncher();

    return () => {
      intercomShowLauncher();
    };
  }, []);
};
