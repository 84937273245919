import { FC } from 'react';
import { IoIosArrowRoundForward } from '@react-icons/all-files/io/IoIosArrowRoundForward';

import { SubmitButtonWithIcon } from './SubmitButtonWithIcon';

type SubmitButtonWithArrowProps = {
  text: string;
};

export const SubmitButtonWithArrow: FC<SubmitButtonWithArrowProps> = ({ text }) => (
  <SubmitButtonWithIcon text={text} icon={<IoIosArrowRoundForward size={24} />} />
);
