import { FC } from 'react';

import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { ListingCondition } from 'types';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestPriceSlideout } from './RequestPriceSlideout';
import { RequestYearSlideout } from './RequestYearSlideout';

import { SelectionItem } from '../SelectionItem';

export const RequestConditionSlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { condition, setCondition } = usePurchaseRequest();

  const onConditionSelect = (value: ListingCondition) => {
    setCondition(value);
    const NextSlideout = value === ListingCondition.Unworn ? RequestYearSlideout : RequestPriceSlideout;

    openModal(<NextSlideout closeModal={closeModal} />, true);
  };

  const items = [
    {
      title: 'Unworn only',
      description:
        'We will only show you examples that have not been worn since they were purchased from an authorized dealer or boutique. There may be slight signs of wear from handling.',
      onClick: () => onConditionSelect(ListingCondition.Unworn),
      isSelected: condition === ListingCondition.Unworn
    },
    {
      title: 'Open to pre-owned',
      description:
        'These watches will have been worn and may have been serviced and/or polished. You’ll be able to review all dealer submissions to your request before purchasing.',
      onClick: () => onConditionSelect(ListingCondition.Preowned),
      isSelected: condition === ListingCondition.Preowned
    }
  ];

  return (
    <PurchaseRequestSlideout
      title='What condition are you looking for?'
      subtitle='We’ll make sure you only see examples that fit your specific condition requirements'
      {...props}
    >
      <div className='d-flex flex-column align-items-center pb-5'>
        {items.map(item => (
          <SelectionItem key={item.title} {...item} />
        ))}
      </div>
    </PurchaseRequestSlideout>
  );
};
