import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Text } from '../Text';

type SlideoutTitleProps = {
  isDark?: boolean;
  title?: ReactNode;
  className?: string;
};
export const SlideoutTitle: FC<SlideoutTitleProps> = ({ isDark, title, className }) => (
  <Text
    color={isDark ? 'light' : 'primary'}
    className={classNames('w-100 fs-20px px-4 text-center opacity-90 riforma-medium', className)}
  >
    {title}
  </Text>
);
