import { FC } from 'react';
import { FaCheck } from '@react-icons/all-files/fa6/FaCheck';
import classNames from 'classnames';

import { formatPrice } from 'utils/text-helpers';

type PricePillProps = {
  isDark: boolean;
  price: number;
};

export const PricePill: FC<PricePillProps> = ({ isDark, price }) => (
  <div className='d-flex flex-column align-items-center'>
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center py-3 px-4 mw-100 border-radius-60',
        isDark ? 'bg-pale12 text-turquoise' : 'bg-green12 text-success'
      )}
    >
      <FaCheck size={16} />
      <span className='ps-3 fs-14px fs-sm-16px'>{formatPrice(price)}</span>
    </div>
  </div>
);
