import { FC } from 'react';
import classNames from 'classnames';

import { Text } from 'components/design-system/Text';

type ApplicableTaxesNoteProps = {
  color?: 'primary' | 'light';
  className?: string;
};
export const ApplicableTaxesNote: FC<ApplicableTaxesNoteProps> = ({ color = 'primary', className }) => (
  <Text color={color} className={classNames('opacity-50 fs-12px text-center', className)}>
    *Applicable taxes and fees will be added at checkout
  </Text>
);
