import { Analytics } from 'context/analytics';
import { PurchaseRequest } from 'types';

import { fireTrackEvent } from './helpers';

type SegmentPayload = {
  brandName: string;
  modelId: number;
  modelName: string;
  referenceNumber: string;
  imageUrl: string;
  unwornRestriction?: 'REQUIRE';
  yearRestriction?: string;
  price: string;
  currency: 'USD';
};

export const logSubmittedPurchaseRequest = (analytics: Analytics, request: PurchaseRequest) => {
  if (!request) {
    return;
  }

  const payload: SegmentPayload = {
    modelId: request.model.id,
    modelName: request.model.displayName,
    referenceNumber: request.model.referenceNumber,
    brandName: request.model.brand?.displayName,
    imageUrl: request.model.images?.[0]?.optimizedUrl || '',
    price: (request.requestedPriceCents / 100).toFixed(2),
    currency: 'USD'
  };

  if (request.unwornRestriction === 'REQUIRE') {
    payload.unwornRestriction = 'REQUIRE';
  }

  if (request.yearRestriction) {
    payload.yearRestriction = String(request.yearRestriction);
  }

  fireTrackEvent(analytics, 'Submitted Purchase Request', payload);
};
