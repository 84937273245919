import { FC } from 'react';
import { FaChevronRight } from '@react-icons/all-files/fa6/FaChevronRight';
import classNames from 'classnames';

import { BottomSheet } from 'components/design-system/BottomSheet';
import { useModal } from 'context/modal/ModalContext';
import { intercomShowNewMessage } from 'lib/intercom';
import styles from 'styles/PurchaseRequest.module.scss';
import { PurchaseRequest } from 'types';

import { CloseRequestSlideout } from '../CloseRequestSlideout';

type ActionButtonProps = {
  onClick: () => void;
  title: string;
};
const ActionButton: FC<ActionButtonProps> = ({ onClick, title }) => (
  <div
    role='button'
    style={{ height: 72 }}
    onClick={onClick}
    className={classNames(
      styles.requestBoardButton,
      'd-flex mt-2 align-items-center justify-content-between px-3 border-primary12 border border-radius-20'
    )}
  >
    <span className='fs-16px'>{title}</span>
    <FaChevronRight />
  </div>
);

export type ActionsBottomSheetProps = {
  onClose: () => void;
  request: PurchaseRequest;
  refreshRequests?: () => void;
  canCloseRequest: boolean;
};

export const ActionsBottomSheet: FC<ActionsBottomSheetProps> = ({
  onClose,
  request,
  refreshRequests,
  canCloseRequest
}) => {
  const { closeModal, openModal } = useModal();

  const openCloseRequestSlideout = () => {
    openModal(
      <CloseRequestSlideout refreshRequests={refreshRequests} request={request} closeModal={closeModal} />,
      true
    );
  };

  return (
    <BottomSheet
      className='pb-6 pb-md-7'
      onClose={onClose}
      title={
        <div className='fs-20px mb-4 riforma-medium text-primary text-center letter-spacing-007'>More options</div>
      }
    >
      {canCloseRequest && <ActionButton title='Cancel request' onClick={openCloseRequestSlideout} />}
      <ActionButton title='Chat with us' onClick={intercomShowNewMessage} />
    </BottomSheet>
  );
};
