import { FC, useEffect } from 'react';

import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { toaster } from 'components/design-system/Toaster';
import { useAnalytics } from 'context/analytics';
import { useBezelUser } from 'context/bezelUser';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { clientApiPost } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { logPurchaseRequestStarted } from 'lib/events';

import { AddedToWantsConfirmationSlideout } from './AddedToWantsConfirmationSlideout';
import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestConditionSlideout } from './RequestConditionSlideout';

import { SelectionItem } from '../SelectionItem';

export const RequestUrgencySlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { refresh, user } = useBezelUser();
  const analytics = useAnalytics();
  const { model, setIsUrgencySlideoutVisible } = usePurchaseRequest();

  useEffect(() => {
    setIsUrgencySlideoutVisible(false);
  }, [setIsUrgencySlideoutVisible]);

  const addListingToWants = async () => {
    if (!model?.id) {
      toaster.error('No model selected. Please select model');

      return;
    }
    try {
      toaster.info('Adding to wants...');
      await clientApiPost(apiUrls.addToWants(model.id), { buyerId: user?.buyerProfile?.id });
      await refresh();
      openModal(<AddedToWantsConfirmationSlideout closeModal={closeModal} />);
    } catch {
      toaster.error('Adding to wants failed. Please try again');
    }
  };

  const onReadyBuyNow = () => {
    openModal(<RequestConditionSlideout closeModal={closeModal} />, true);
    logPurchaseRequestStarted(analytics, model);
  };

  const items = [
    {
      title: 'You’re ready to buy now',
      description:
        'You set the price and the condition, we’ll let all our sellers know this is what you’re looking for and they’ll do their best to make it happen.',
      onClick: onReadyBuyNow
    },
    {
      title: 'Just add it to your wants',
      description: 'If you just want to stay up to date on the model, we’ll just add it to your wants.',
      onClick: addListingToWants
    }
  ];

  return (
    <PurchaseRequestSlideout
      title='How quickly do you want the watch?'
      subtitle='Let us know the urgency and it will help us tailor the request '
      {...props}
    >
      <div className='d-flex flex-column align-items-center pb-5'>
        {items.map(item => (
          <SelectionItem key={item.title} {...item} />
        ))}
      </div>
    </PurchaseRequestSlideout>
  );
};
