import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from 'styles/ListingInfo.module.scss';
import { StyledComponent } from 'types';

export type ConditionLabelValueGroupProps = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
} & StyledComponent;

export const ConditionLabelValueGroup: FC<ConditionLabelValueGroupProps> = ({ label, value, className, style }) => (
  <div
    style={style}
    className={classNames('px-3 my-3 border-start d-flex flex-column justify-content-between', className)}
  >
    <span className='text-primary fs-16px riforma-regular mb-1'>
      {label}
      <span className='invisible'>:&nbsp;</span>
    </span>
    <span className={`text-secondary fs-14px riforma-regular ${styles.subheading}`}>{value}</span>
  </div>
);
