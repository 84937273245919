import { GoDotFill } from '@react-icons/all-files/go/GoDotFill';
import { MdOutlineKeyboardArrowRight } from '@react-icons/all-files/md/MdOutlineKeyboardArrowRight';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { formatPrice } from 'utils/text-helpers';

const placeholderUrl = 'bezel-web/purchase_requests_onboarding_hero_model.png';

export const StaticRequestItem = () => (
  <div
    style={{ bottom: 45, height: 70 }}
    className='bg-white position-absolute border border-primary12 px-2 py-2 justify-content-between align-items-center riforma-regular w-90 rounded-2 d-flex'
  >
    <div className='me-1'>
      <CloudinaryImage width={37} height={46} alt='image placeholder' src={placeholderUrl} />
    </div>
    <div className='w-60'>
      <div className='fs-14px riforma-medium opacity-90 text-start'>Any year</div>
      <div className='fs-10px d-flex'>
        <span className='d-flex letter-spacing-08 align-items-center text-success pe-1'>UNWORN</span>
        <span className='opacity-60 d-flex align-items-center'>
          <GoDotFill />5 DAYS LEFT
        </span>
      </div>
    </div>
    <div className='d-flex align-items-center justify-content-end'>
      <div className='fs-24px text-success'>{formatPrice(950000)}</div>
      <div className='opacity-30 ms-1 d-flex align-items-center justify-content-center'>
        <MdOutlineKeyboardArrowRight size={25} />
      </div>
    </div>
  </div>
);
