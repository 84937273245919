import { FC } from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Pill } from 'components/design-system/Pill';
import { buildListingUrl } from 'lib/url_helpers';
import styles from 'styles/PurchaseRequest.module.scss';
import { Listing, ListingImageType, PurchaseRequestOffer } from 'types';
import { formatPrice } from 'utils/text-helpers';

type ListingItemProps = {
  listing: Listing;
  customUrl?: string;
  offerStatus?: PurchaseRequestOffer['status'];
};

const infoTagMap = {
  ACCEPTED: {
    title: 'Accepted',
    color: 'success'
  },
  AWAITING_RESPONSE: {
    title: 'Submitted to the buyer',
    color: 'green'
  },
  PENDING_REVIEW: {
    title: 'Listing in review',
    color: 'secondary'
  },
  CLOSED: {
    title: 'Closed',
    color: 'warning'
  },
  EXPIRED: {
    title: 'Expired',
    color: 'warning'
  },
  REJECTED: {
    title: 'Rejected',
    color: 'warning'
  }
} as const satisfies Record<PurchaseRequestOffer['status'], { title: string; color: string }>;

export const ListingItem: FC<ListingItemProps> = ({ listing, customUrl, offerStatus }) => {
  const url = buildListingUrl(
    listing.model.brand.displayName,
    listing.model.displayName,
    listing.model.referenceNumber,
    listing.id
  );
  const imageUrl =
    listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || 'model_placeholder.png';

  let accessories = '';

  if (listing.accessories.length > 0) {
    accessories += 'with';

    listing.accessories.forEach((accessory, index) => (accessories += `${index > 0 ? ',' : ''} ${accessory.name}`));
  } else if (listing.manufactureYear) {
    accessories += 'with no additional accessories';
  } else {
    accessories += 'No additional accessories';
  }

  return (
    <a
      href={customUrl || url}
      target='_blank'
      role='button'
      className={classNames(
        'd-flex mt-1 mb-2 w-100 justify-content-between p-1 rounded-2 border border-primary12 text-primary',
        styles.selectionItem
      )}
      style={{ height: 152 }}
      key={listing.id}
    >
      <div className='d-flex h-100 w-60 flex-column justify-content-between position-relative ps-3 py-3'>
        <div>
          <div className='fs-14px'>{capitalize(listing.condition.toLowerCase())}</div>
          <div className='riforma-regular opacity-60 fs-12px clamp-lines-4 pe-1'>
            {listing.manufactureYear}
            {listing.manufactureYear ? ' ' : ''}
            {accessories}
          </div>
        </div>
        <div className='text-success fs-14px'>{formatPrice(listing.priceCents)}</div>
      </div>
      <div className='w-40 h-100 position-relative border-radius-16 overflow-hidden'>
        <div className='position-absolute d-flex align-items-center justify-content-center w-100' style={{ zIndex: 1 }}>
          {offerStatus && (
            <Pill
              className='text-white fs-10px border-radius-46 px-2 py-1 riforma-regular'
              title={infoTagMap[offerStatus]?.title}
              color={infoTagMap[offerStatus].color}
            />
          )}
        </div>
        <CloudinaryImage
          fill
          style={{ objectFit: 'cover' }}
          src={imageUrl}
          alt={`${listing.model.displayName} image`}
        />
      </div>
    </a>
  );
};
