import classNames from 'classnames';
import useSWR from 'swr';

import { ContentLoader } from 'components/design-system/ContentLoader';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import styles from 'styles/PurchaseRequest.module.scss';
import { Listing } from 'types';

import { getCommonParams } from './helpers';

import { AvailableListingsSlideout } from '../AvailableListingsSlideout';

const bottomStyles = { bottom: 15 };

export const AvailableListingsButton = () => {
  const { closeModal, openModal } = useModal();
  const { model } = usePurchaseRequest();

  const { data: availableListings, isLoading: isAvailableListingsLoading } = useSWR<Listing[]>(
    apiUrls.listings(getCommonParams(model?.id)),
    clientApiGet
  );

  if (isAvailableListingsLoading) {
    return (
      <div className='position-absolute' style={bottomStyles}>
        <ContentLoader height={20} width={185} />
      </div>
    );
  }

  if (availableListings && availableListings.length > 0) {
    return (
      <div
        role='button'
        onClick={() =>
          openModal(<AvailableListingsSlideout closeModal={closeModal} listings={availableListings} />, true)
        }
        className={classNames('position-absolute fs-14px text-decoration-underline', styles.clickableText)}
        style={bottomStyles}
      >
        {availableListings.length} listings available right now
      </div>
    );
  }

  return null;
};
