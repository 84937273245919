import { useState } from 'react';

import { toaster } from 'components/design-system/Toaster';
import { useBezelUser } from 'context/bezelUser';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { clientApiPost } from 'lib/api_helper';
import { CreatePurchaseRequestBody, ListingCondition, PurchaseRequest } from 'types';

export const useCreatePurchaseRequest = () => {
  const { condition, model, price, year } = usePurchaseRequest();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useBezelUser();

  const createRequest = async () => {
    try {
      if (!model || !user?.buyerProfile) {
        return;
      }
      setIsLoading(true);

      const body: CreatePurchaseRequestBody = {
        buyerProfile: user?.buyerProfile?.id,
        model: model?.id,
        requestedPriceCents: price || 0,
        yearRestriction: year
      };

      if (condition === ListingCondition.Unworn) {
        body['unwornRestriction'] = 'REQUIRE';
      }

      const result = await clientApiPost<PurchaseRequest, CreatePurchaseRequestBody>(
        '/api/purchaseRequests/request',
        body
      );

      return result;
    } catch (error) {
      console.log(error);
      toaster.error('Creating purchase request failed. Please try again');
    } finally {
      setIsLoading(false);
    }
  };

  return { createRequest, isLoading };
};
