import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from 'styles/PurchaseRequest.module.scss';
import { PurchaseRequestOffer } from 'types';

import { Countdown } from './Countdown';

type InfoTagProps = {
  offer: PurchaseRequestOffer;
};

export const InfoTag: FC<InfoTagProps> = ({ offer }) => {
  let content: ReactNode;
  const isEnded = offer.status === 'REJECTED' || offer.status === 'EXPIRED' || offer.status === 'CLOSED';
  let className;

  if (offer.status === 'PENDING_REVIEW') {
    className = styles.tagBlurred;
    content = 'PENDING';
  } else if (offer.status === 'ACCEPTED' || isEnded) {
    className = isEnded ? 'bg-danger-pale' : 'bg-success';
    content = offer.status;
  } else {
    className = styles.tagBlurred;
    content = <Countdown expiration={offer.expiration} />;
  }

  return (
    <div
      style={{ top: 20, right: 20 }}
      className={classNames('position-absolute px-3 py-1 text-light fs-10px border-radius-36', className)}
    >
      {content}
    </div>
  );
};
