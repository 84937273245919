import { FC } from 'react';
import Link from 'next/link';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { SlideoutBackButton } from 'components/design-system/Slideout';
import { SlideoutCloseButton } from 'components/design-system/Slideout/SlideoutCloseButton';
import { pageRoutes } from 'lib/routes';
import { buildModelUrl } from 'lib/url_helpers';
import { Model } from 'types';

type PurchaseRequestHeaderProps = {
  onClickPrevious?: () => void;
  onClose: () => void;
  model?: Model;
};

export const PurchaseRequestHeader: FC<PurchaseRequestHeaderProps> = ({ onClickPrevious, model, onClose }) => {
  const imageUrl = model?.images?.[0]?.optimizedUrl || 'model_placeholder.png';
  const modelUrl =
    model && model.brand
      ? buildModelUrl(model.brand.displayName, model.displayName, model.referenceNumber, model.id)
      : pageRoutes.home;

  return (
    <>
      <div className='d-flex px-4 align-items-center w-100 position-relative' style={{ height: 89, minHeight: 89 }}>
        {onClickPrevious && <SlideoutBackButton className='position-static' onClickPrevious={onClickPrevious} />}
        <Link target='_blank' href={modelUrl} className='d-flex ms-4 flex-1 align-items-center'>
          <CloudinaryImage
            height={50}
            width={60}
            style={{ objectFit: 'contain' }}
            src={imageUrl}
            alt={model?.displayName || 'Model image'}
          />
          <div className='fs-12px me-2'>{model?.displayName}</div>
        </Link>
        <SlideoutCloseButton className='position-static' onClick={onClose} />
      </div>
      <div className='mx-4 border-bottom mb-3' />
    </>
  );
};
