import { FC } from 'react';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import classNames from 'classnames';

import { formatPrice } from 'utils/text-helpers';

type BuyerMessageProps = {
  isBuyer: boolean;
  isBuyerInitiator?: boolean;
  title?: string;
  status?: 'ACCEPTED' | 'REJECTED' | 'ORDERED' | 'EXPIRED';
  price?: number;
  onClick?: () => void;
  extraMessage?: string;
};

export const BuyerMessage: FC<BuyerMessageProps> = ({
  isBuyer,
  isBuyerInitiator = true,
  title,
  status,
  price,
  onClick,
  extraMessage
}) => {
  const isAccepted = status === 'ACCEPTED';
  const isRejected = status === 'REJECTED';
  const isOrdered = status === 'ORDERED';
  const isExpired = status === 'EXPIRED';

  let label = '';

  const isYourMessage = (isBuyer && isBuyerInitiator) || (!isBuyer && !isBuyerInitiator);

  if (isYourMessage) {
    label = 'You';
  } else if (isBuyer) {
    label = 'Seller';
  } else {
    label = 'Buyer';
  }

  return (
    <div
      onClick={onClick}
      className={classNames('d-flex flex-column w-100', {
        'align-items-end': isYourMessage,
        'cursor-pointer': onClick
      })}
    >
      <div className='opacity-60 fs-12px riforma-regular'>{label}</div>
      <div
        className={classNames('position-relative border-radius-24 bg-primary px-4 py-3', {
          'bg-lightGreen': isAccepted || isOrdered,
          'bg-primary': isBuyer && !isAccepted,
          'bg-lightGrey': !isBuyer && !isAccepted && !isExpired,
          'bg-danger-pale': isExpired
        })}
        style={{ width: 'fit-content' }}
      >
        <div className='text-center mx-1'>
          {title && (
            <div
              className={classNames('fs-12px opacity-60 letter-spacing-08', isBuyer ? 'text-light' : 'text-primary')}
            >
              {title}
            </div>
          )}

          {price && (
            <div className={classNames('riforma-regular fs-24px', isBuyer ? 'text-light' : 'text-primary')}>
              {formatPrice(price)}
            </div>
          )}
          {isOrdered && <div className='fs-16px opacity-90 riforma-regular text-success'>Ordered offer</div>}
          {isAccepted && <div className='fs-16px opacity-90 riforma-regular text-success'>Accepted offer</div>}
          {isRejected && (
            <div
              className={classNames('fs-16px opacity-90 riforma-regular', {
                'text-light': isBuyer
              })}
            >
              Rejected offer
            </div>
          )}
          {extraMessage && <div className='fs-12px text-secondary riforma-regular'>{extraMessage}</div>}
        </div>
        {onClick && (
          <div
            className={classNames('position-absolute', isBuyer ? 'text-light' : 'text-primary')}
            style={{ right: 6, bottom: '40%' }}
          >
            <IoIosArrowForward />
          </div>
        )}
      </div>
    </div>
  );
};
