import { FC } from 'react';
import classNames from 'classnames';

import { ModelSelector } from 'components/common/ModelSelector/ModelSelector';
import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';
import { SearchComponent } from 'components/seller/SearchComponent';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import styles from 'styles/Seller.module.scss';
import { Model } from 'types';

import { RequestConditionSlideout } from './RequestConditionSlideout';
import { RequestUrgencySlideout } from './RequestUrgencySlideout';

export const SelectModelSlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { setModel, isUrgencySlideoutVisible } = usePurchaseRequest();

  const selectModel = (model: Model) => {
    setModel(model);
    openModal(
      isUrgencySlideoutVisible ? (
        <RequestUrgencySlideout closeModal={closeModal} />
      ) : (
        <RequestConditionSlideout closeModal={closeModal} />
      ),
      true
    );
  };

  return (
    <Slideout bodyClassName='p-4 pt-0' title='What watch are you looking for right now?' {...props}>
      <ModelSelector
        itemsPerRow={2}
        onSelectModel={selectModel}
        renderTitle={setFilteredQuery => (
          // todo improve this logic to avoid negative margins
          <div
            className='py-1 bg-light'
            style={{
              position: 'sticky',
              top: -8,
              zIndex: 2,
              marginLeft: -20,
              paddingLeft: 20,
              marginRight: -20,
              paddingRight: 20,
              height: 54
            }}
          >
            <SearchComponent
              inputWrapClassName='h-100'
              className='w-100 border border-1 rounded-2 h-100'
              onSetSearchValue={setFilteredQuery}
              placeholder='Tell us what you’re looking for...'
              inputClassName={classNames('bg-primary04 h-100 fs-16px', styles['search-new-listing-input'])}
              iconClassName={styles['search-new-listing-input-icon']}
            />
          </div>
        )}
      />
    </Slideout>
  );
};
