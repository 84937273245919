import { FC } from 'react';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { getTitleFromModel } from 'lib/getTitleFromModel';
import styles from 'styles/ModelCard.module.scss';
import { Model } from 'types';

export const ModelSelectorCardTestIds = {
  container: 'model-selector-listing-model-card-container'
};

export type ModelSelectorCardProps = {
  model: Model;
  className?: string;
};

export const ModelSelectorCard: FC<ModelSelectorCardProps> = ({ model, className }) => {
  const title = getTitleFromModel(model);

  return (
    <div
      data-testid={ModelSelectorCardTestIds.container}
      className={classNames(
        'border shadow-on-hover rounded-2 overflow-hidden p-4 text-center riforma-regular h-100 cursor-pointer',
        className
      )}
    >
      <CloudinaryImage
        responsive
        alt={`${title} Model Image`}
        sizes='25vw'
        src={model.images.length > 0 ? model.images[0].url : 'model_placeholder.png'}
        style={{ objectFit: 'contain' }}
      />
      <div className={`text-secondary riforma-medium fs-10px letter-spacing-1 mt-4 mb-2 ${styles.title}`}>
        {model.brand.name.toUpperCase()}
      </div>
      <div className={classNames('text-primary riforma-regular', styles.subtitle, 'fs-12px w-100')}>
        {model.name} {model.referenceNumber}
      </div>
    </div>
  );
};
