import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { LookingForWatchSlideout } from 'components/purchaseRequests/Slideouts/LookingForWatchSlideout';
import {
  REQUEST_REMINDER_TIMESTAMP,
  SHOULD_OPEN_REQUESTS_SLIDEOUT,
  SHOULD_SHOW_REQUESTS_ONBOARDING
} from 'consts/storageKeys';
import { useGetTreatmentState } from 'context/featureToggle';
import { PURCHASE_REQUESTS_ANNOUNCEMENT } from 'context/featureToggle/consts';
import { useModal } from 'context/modal/ModalContext';
import { useViewport } from 'context/viewport';
import { getFromStorage, removeFromStorage } from 'lib/storageHelpers';
import { User } from 'types';

export const useShowRequestOnboarding = (user?: User) => {
  const { isMobile, isLoaded } = useViewport();
  const { pathname } = useRouter();
  const { closeModal, openModal } = useModal();
  const { isEnabled, isLoading } = useGetTreatmentState(PURCHASE_REQUESTS_ANNOUNCEMENT);

  useEffect(() => {
    const shouldOpenRequests = getFromStorage(SHOULD_OPEN_REQUESTS_SLIDEOUT) === 'true';
    const shouldShowRequestsOnboarding = getFromStorage(SHOULD_SHOW_REQUESTS_ONBOARDING) === 'true';

    if (
      !user ||
      isLoading ||
      !isLoaded ||
      !isEnabled ||
      shouldOpenRequests ||
      pathname.includes('checkout') ||
      pathname.includes('announcement') ||
      (!shouldShowRequestsOnboarding && isMobile)
    ) {
      return;
    }

    const reminderDate = getFromStorage(REQUEST_REMINDER_TIMESTAMP, 'localStorage');

    if (reminderDate === 'never') {
      return;
    }

    const now = Date.now();

    if (now > Number(reminderDate)) {
      removeFromStorage(REQUEST_REMINDER_TIMESTAMP, 'localStorage');
      openModal(<LookingForWatchSlideout closeModal={closeModal} />);
    }

    removeFromStorage(SHOULD_SHOW_REQUESTS_ONBOARDING);
  }, [closeModal, isEnabled, isLoaded, isLoading, isMobile, openModal, pathname, user]);
};
