export const brandItems = [
  { brandKey: 'rolex', title: 'Rolex' },
  { brandKey: 'audemars-piguet', title: 'Audemars Piguet' },
  { brandKey: 'cartier', title: 'Cartier' },
  { brandKey: 'patek-philippe', title: 'Patek Philippe' },
  { brandKey: 'vacheron-constantin', title: 'Vacheron Constantin' },
  { brandKey: 'a-lange-&-söhne', title: 'A. Lange & Söhne' },
  { brandKey: 'omega', title: 'Omega' },
  { brandKey: 'panerai', title: 'Panerai' },
  { brandKey: 'tudor', title: 'Tudor' },
  { brandKey: 'iwc', title: 'IWC' },
  { brandKey: 'breitling', title: 'Breitling' }
];

export const priceItems = [
  { title: 'Under $5K', priceKey: 'under-5000' },
  { title: 'Under $10K', priceKey: 'under-10000' },
  { title: '$10K-20K', priceKey: '10000-20000' },
  { title: '$20K-50K', priceKey: '20000-50000' },
  { title: '$50K+', priceKey: 'over-50000' }
];

export const tabs = [
  { title: 'Orders', href: '/orders' },
  { title: 'Offers', href: '/offers' },
  { title: 'Requests', href: '/requests' },
  { title: 'Auctions', href: '/your-auctions' },
  { title: 'Wants', href: '/wants' }
];

const dropdownTabs = [
  { title: 'Account', href: '/account/profile' },
  { title: 'Refer a friend', href: '/refer' }
];

export const dropdownItems = tabs.concat(dropdownTabs);

export enum Title {
  Brands = 'Brands',
  Prices = 'Prices',
  FeaturedCollections = 'Featured Collections',
  Account = 'Your Account'
}
