import { FC, ReactNode } from 'react';

type SubmitButtonWithIconProps = {
  text: string;
  icon: ReactNode;
};

export const SubmitButtonWithIcon: FC<SubmitButtonWithIconProps> = ({ text, icon }) => (
  <div className='d-flex justify-content-between align-items-center'>
    <span className='w-10' />
    <span className='flex-1'>{text}</span>
    <span className='w-10'>{icon}</span>
  </div>
);
