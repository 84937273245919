import { CSSProperties, FC, PropsWithChildren } from 'react';
import { AiOutlineCheck } from '@react-icons/all-files/ai/AiOutlineCheck';
import classNames from 'classnames';

import { COLORS } from 'consts';
import styles from 'styles/Seller.module.scss';

export const SelectionLabelTestIds = {
  container: 'selection-label-container',
  checkbox: 'selection-label-checkbox'
};

export type SelectionLabelProps = {
  title: string | JSX.Element | number;
  onClick?: () => void;
  isSelected?: boolean;
  description?: string;
  className?: string;
  contentClassName?: string;
} & PropsWithChildren;

const size = 16;
const checkBoxStyle: CSSProperties = { minWidth: `${size}px`, width: `${size}px`, height: `${size}px` };
const labelStyle = { minHeight: '62px' };

export const SelectionLabel: FC<SelectionLabelProps> = ({
  title,
  onClick,
  isSelected,
  description,
  className,
  contentClassName,
  children
}) => (
  <div
    data-testid={SelectionLabelTestIds.container}
    key={typeof title === 'string' ? title : Math.random()}
    className={classNames('cursor-pointer riforma-regular', className)}
    onClick={onClick}
  >
    <div
      className={classNames(
        'px-3 d-flex py-3 align-items-center justify-content-between border rounded-2 border-1 position-relative fs-14px position-relative',
        !isSelected && styles.selectionLabel,
        isSelected && 'border-success text-success',
        contentClassName
      )}
      style={labelStyle}
    >
      <div className='w-100'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          {title}
          <div
            data-testid={SelectionLabelTestIds.checkbox}
            className={classNames(
              'rounded-50 ms-3 border border-1 d-flex align-items-center position-relative justify-content-center',
              isSelected && 'border-success bg-success'
            )}
            style={checkBoxStyle}
          >
            <AiOutlineCheck color={COLORS.white} size={10} />
          </div>
        </div>
        {description && <div className='fs-12px opacity-60 w-80 clamp-lines-1'>{description}</div>}
        {children}
      </div>
    </div>
  </div>
);
