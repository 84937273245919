import { FC } from 'react';
import classNames from 'classnames';

import { getFormattedCountdownDate, useCountdown } from 'hooks/useCountdown';

type CountdownProps = {
  expiration: string;
  className?: string;
};

export const Countdown: FC<CountdownProps> = ({ expiration, className }) => {
  const countdown = useCountdown(expiration);
  const formattedCountdownDate = getFormattedCountdownDate(countdown, expiration);

  return <div className={classNames('text-uppercase', className)}>{formattedCountdownDate}</div>;
};
