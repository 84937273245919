import { FC } from 'react';
import classNames from 'classnames';

import { intercomShowNewMessage } from 'lib/intercom';
import styles from 'styles/ListingInfo.module.scss';

type AnyQuestionsHelperProps = {
  className?: string;
};
export const AnyQuestionsHelper: FC<AnyQuestionsHelperProps> = ({ className }) => (
  <div className={classNames('fs-14px text-secondary', className)}>
    Any questions?{' '}
    <span onClick={intercomShowNewMessage} className={classNames('cursor-pointer', styles.askUs)}>
      Ask us
    </span>
  </div>
);
