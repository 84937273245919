import { CSSProperties } from 'react';

export * from './address';
export * from './base';
export * from './brand';
export * from './buyerProfile';
export * from './cluster';
export * from './invoice';
export * from './issue';
export * from './listing';
export * from './listingStat';
export * from './model';
export * from './order';
export * from './paymentTransaction';
export * from './reservation';
export * from './responses';
export * from './series';
export * from './homeFeed';
export * from './feature';
export * from './collection';
export * from './article';
export * from './offer';
export * from './user';
export * from './sellerLevel';
export * from './sellerProfile';
export * from './sitemap';
export * from './review';
export * from './similar';
export * from './selectedSale';
export * from './category';
export * from './listingWithOffers';
export * from './purchaseRequest';

export interface StyledComponent {
  className?: string;
  style?: CSSProperties;
}
