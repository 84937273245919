import { FC, useState } from 'react';
import { withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import { useStripe } from '@stripe/react-stripe-js';

import { onlyCardPayment, showPaymentToastError } from 'components/checkout/payment/constants';
import { Payment } from 'components/checkout/payment/Payment';
import { YourWatch } from 'components/common/summary/YourWatch';
import { SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { ConditionLabelValueGroup } from 'components/listing_detail/ConditionLabelValueGroup';
import { useAnalytics } from 'context/analytics';
import { useBezelUser } from 'context/bezelUser';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { logSubmittedPurchaseRequest } from 'lib/events/log_submitted_purchase_request';
import { withElements } from 'lib/stripe';
import { Listing, ListingCondition, PaymentMethod } from 'types';
import { formatPrice } from 'utils/text-helpers';

import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';
import { RequestSubmittedSlideout } from './RequestSubmittedSlideout';

import { useCreatePurchaseRequest } from '../createPurchaseRequest';

type RequestHoldSlideoutProps = SlideoutCommonProps;

const RequestHoldSlideoutComponent: FC<RequestHoldSlideoutProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { model, price = 0, condition } = usePurchaseRequest();
  const { refresh } = useBezelUser();
  const analytics = useAnalytics();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);

  const holdValue = 0.025 * (price || 0);

  const listing = { model, images: [] } as unknown as Listing;

  const { createRequest } = useCreatePurchaseRequest();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (!stripe) {
        showPaymentToastError();

        return;
      }
      const purchaseRequest = await createRequest();

      // for requests that don't require hold
      if (purchaseRequest?.status === 'LIVE') {
        openModal(<RequestSubmittedSlideout request={purchaseRequest} closeModal={closeModal} />);
        logSubmittedPurchaseRequest(analytics, purchaseRequest);

        return;
      }

      if (!purchaseRequest?.hold) {
        showPaymentToastError();

        return;
      }

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        purchaseRequest.hold.paymentInstructions.stripePaymentIntentClientSecret,
        {
          payment_method: selectedPaymentMethod?.details?.id
        }
      );

      if (error || (paymentIntent?.status !== 'succeeded' && paymentIntent?.status !== 'requires_capture')) {
        showPaymentToastError();

        return;
      }

      openModal(<RequestSubmittedSlideout request={{ ...purchaseRequest, status: 'LIVE' }} closeModal={closeModal} />);
      logSubmittedPurchaseRequest(analytics, purchaseRequest);
    } catch (error) {
      console.error(error);
      showPaymentToastError();
    } finally {
      setIsLoading(false);
    }
  };

  const isUnworn = condition === ListingCondition.Unworn;
  const conditionText = isUnworn ? 'Unworn' : 'Pre-owned';

  return (
    <PurchaseRequestSlideout
      title={<div className='px-5 mb-4'>Purchase request payment hold</div>}
      submit={{
        textClassname: 'w-100',
        title: <SubmitButtonWithArrow text='Submit request' />,
        onClick: onSubmit,
        disabled: isLoading || !selectedPaymentMethod,
        loading: isLoading
      }}
      footerClassName='mt-2'
      {...props}
    >
      <div className='d-flex flex-column w-100'>
        <div className='mt-2 fs-16px fs-lg-18px line-height-24px opacity-90'>{conditionText} request</div>
        <div className='border-top my-2' />
        <YourWatch hideImageBorder listing={listing} className='w-100 my-2'>
          <div className='text-primary fs-14px'>
            <span className='opacity-60 pe-3 letter-spacing-08'>REQUEST</span>
            <span className='text-success letter-spacing-08'>{formatPrice(price)}</span>
          </div>
        </YourWatch>
        <div className='border-top my-2' />
        <div className='mt-2 mb-1 fs-16px fs-lg-18px line-height-24px'>Purchase request hold</div>
        <div className='fs-14px riforma-regular d-flex justify-content-between align-items-center'>
          <span className='opacity-60'>2.5% Request hold</span>
          <span className='letter-spacing-08 text-success'>{formatPrice(holdValue)}</span>
        </div>
        <Payment
          includeTitle={false}
          allowedPaymentTypes={onlyCardPayment}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          className='my-1 w-100'
          postSubmitNewCard={refresh}
          contentClassName='border-success'
        />
        <div className='riforma-regular opacity-60 fs-14px line-height-18px px-2 mb-2'>
          {isUnworn ? unwornText : preownedText}
        </div>
        <div className='border-top my-2' />
        {isUnworn ? (
          <ConditionLabelValueGroup
            className='fs-14px'
            label='Unworn requests are binding'
            value={
              <span>
                <span>If a seller accepts your request, you’ll have 24 hours to check out. </span>
                <span className='text-decoration-underline'>
                  If you do not complete payment, you will forfeit your request hold.
                </span>
              </span>
            }
          />
        ) : (
          <>
            <ConditionLabelValueGroup
              className='fs-14px'
              label='You’ll review pre-owned submissions'
              value={
                <span className='fs-14px'>
                  Interested sellers will submit their watch to you based on your request. You will then be able to
                  accept or decline the submitted watch.
                </span>
              }
            />
            <ConditionLabelValueGroup
              className='fs-14px'
              label='Once you accept a watch, it’s binding '
              value={
                <span>
                  <span>Once you accept a seller’s submission, you’ll have 24 hours to check out.</span>
                  <span className='text-decoration-underline'>
                    If you do not complete payment, you will forfeit your request hold.
                  </span>
                </span>
              }
            />
          </>
        )}
      </div>
    </PurchaseRequestSlideout>
  );
};

export const RequestHoldSlideout = withPageAuthRequired(withElements(RequestHoldSlideoutComponent));

const unwornText =
  'This charge will be removed once you submit final payment on the watch or if your request expires without a seller acceptance.';
const preownedText =
  "You'll be able to approve or decline seller submissions to this request. This charge will be removed once you submit final payment on a watch you accept or if your request expires without your acceptance of a seller submission.";
