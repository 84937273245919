import { FC, useState } from 'react';
import classNames from 'classnames';

import { CurrencyInput } from 'components/design-system/CurrencyInput';
import { inputStyle } from 'components/design-system/Input';
import { SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { Text } from 'components/design-system/Text';
import { ApplicableTaxesNote } from 'components/offers/ApplicableTaxesNote';
import { maximumPriceCents, minimumPriceCents } from 'consts/price';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import styles from 'styles/PurchaseRequest.module.scss';
import { Listing, ListingCondition } from 'types';
import { formatPrice } from 'utils/text-helpers';

import { AvailableListingsButton } from './AvailableListingsButton';
import { getCommonParams } from './helpers';

import { MatchingListingsSlideout } from '../MatchingListingsSlideout';
import { PurchaseRequestSlideout } from '../PurchaseRequestSlideout';
import { RequestHoldSlideout } from '../RequestHoldSlideout';

type RequestsPriceSlideoutProps = SlideoutCommonProps;

export const RequestPriceSlideout: FC<RequestsPriceSlideoutProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { model, price, setPrice, condition, year, minAvailablePrice } = usePurchaseRequest();
  const [warningText, setWarningText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const stringPrice = price ? String(price / 100) : undefined;

  const checkListingsOnPlatform = async () => {
    try {
      setIsLoading(true);

      const params = {
        ...getCommonParams(model?.id),
        ...(year ? { 'manufactureYear[]': year } : {}),
        ...(condition === ListingCondition.Unworn ? { 'condition[]': condition } : {})
      };

      const listings = await clientApiGet<Listing[]>(apiUrls.listings(params));

      return listings.filter(({ listedPriceCents }) => !price || listedPriceCents <= price);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirm = async () => {
    const results = await checkListingsOnPlatform();

    if (results && results.length > 0) {
      openModal(<MatchingListingsSlideout closeModal={closeModal} listings={results} />, true);
    } else {
      openModal(<RequestHoldSlideout closeModal={closeModal} />, true);
    }
  };

  const recommendedPrice = minAvailablePrice
    ? Math.max(Math.round((minAvailablePrice * 0.9) / 1000) * 1000, minimumPriceCents)
    : null;
  const placeholder = recommendedPrice ? formatPrice(recommendedPrice).slice(1) : 'Your bid';

  const onBlur = () => {
    if (!price) {
      return;
    }

    if (price < minimumPriceCents) {
      setWarningText('Minimum price is $1000');
    } else if (price > maximumPriceCents) {
      setWarningText('Minimum price is $10 million');
    }
  };

  const onRecommendedClick = () => {
    if (!recommendedPrice) {
      return null;
    }
    setPrice(recommendedPrice);
    setWarningText(null);
  };

  return (
    <PurchaseRequestSlideout
      bodyClassName='position-relative'
      title='How much do you want to pay for it?'
      subtitle='We’ll look at our current inventory and broadcast out this bid to sellers '
      submit={{
        textClassname: 'w-100',
        title: <SubmitButtonWithArrow text='Confirm request' />,
        onClick: onConfirm,
        loading: isLoading,
        disabled: isLoading || !price || price < minimumPriceCents || price > maximumPriceCents
      }}
      {...props}
    >
      <div className='d-flex flex-column riforma-regular fs-16px align-items-center'>
        <div
          role='button'
          onClick={onRecommendedClick}
          className={classNames('mt-6 mb-2 fs-13px', styles.clickableText, {
            'pointer-events-none': price !== recommendedPrice && !!price
          })}
          style={{ visibility: recommendedPrice ? 'visible' : 'hidden' }}
        >
          {!price || price === recommendedPrice ? 'RECOMMENDED' : 'REQUESTED'} PRICE
        </div>
        <div className='d-flex justify-content-center w-100 text-primary'>
          <Text style={inputStyle}>$ </Text>
          <CurrencyInput
            autoFocus
            isResponsive
            onFocus={() => setWarningText(null)}
            onBlur={onBlur}
            className='text-center text-primary'
            value={stringPrice}
            placeholder={placeholder}
            onValueChange={value => setPrice(Number(value || 0) * 100)}
            style={{ border: 'none', background: 'none', minWidth: '200px', ...inputStyle }}
          />
        </div>
        {warningText && <div className='text-danger fs-12px d-block text-center mt-4'>{warningText}</div>}
        <ApplicableTaxesNote className='mt-5' />
        <AvailableListingsButton />
      </div>
    </PurchaseRequestSlideout>
  );
};
