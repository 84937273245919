import { FC, useEffect, useMemo, useState } from 'react';

import { SlideoutCommonProps } from 'components/design-system/Slideout';
import { Listing, ListingCondition } from 'types';

import { ListingItem } from './components/ListingItem';
import { TabSelector } from './components/TabSelector';
import { PurchaseRequestSlideout } from './PurchaseRequestSlideout';

type AvailableListingsSlideoutProps = { listings: Listing[] } & SlideoutCommonProps;

type SplitPurchaseRequests = {
  [ListingCondition.Unworn]: Listing[];
  [ListingCondition.Preowned]: Listing[];
};

export const AvailableListingsSlideout: FC<AvailableListingsSlideoutProps> = ({ listings, ...props }) => {
  const [selectedTab, setSelectedTab] = useState<ListingCondition>(ListingCondition.Unworn);

  const splitListings = useMemo(
    () =>
      listings
        .sort((a, b) => b.priceCents - a.priceCents)
        .reduce<SplitPurchaseRequests>(
          (acc, val) => {
            if (val?.condition === ListingCondition.Unworn) {
              acc[ListingCondition.Unworn].push(val);
            } else {
              acc[ListingCondition.Preowned].push(val);
            }

            return acc;
          },
          {
            [ListingCondition.Unworn]: [],
            [ListingCondition.Preowned]: []
          }
        ),
    [listings]
  );

  useEffect(() => {
    if (splitListings[ListingCondition.Unworn].length === 0 && splitListings[ListingCondition.Preowned].length > 0) {
      setSelectedTab(ListingCondition.Preowned);
    }
  }, [splitListings]);

  return (
    <PurchaseRequestSlideout title='Available listings for this specific model' {...props}>
      <div className='w-100 mt-3 pb-5 d-flex flex-column align-items-center'>
        <TabSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} splitData={splitListings} />
        <div className='w-100 mt-3' style={{ overflowY: 'auto' }}>
          {splitListings[selectedTab].length === 0 ? (
            <div className='text-center riforma-regular fs-16px opacity-90 mt-5'>
              No {selectedTab.toLowerCase()} listings currently available
            </div>
          ) : (
            splitListings[selectedTab].map(item => <ListingItem listing={item} key={item.id} />)
          )}
        </div>
      </div>
    </PurchaseRequestSlideout>
  );
};
