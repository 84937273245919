import { FaCcDinersClub } from '@react-icons/all-files/fa6/FaCcDinersClub';
import { FaCcDiscover } from '@react-icons/all-files/fa6/FaCcDiscover';
import { FaCcJcb } from '@react-icons/all-files/fa6/FaCcJcb';
import { FaCcMastercard } from '@react-icons/all-files/fa6/FaCcMastercard';
import { FaCcVisa } from '@react-icons/all-files/fa6/FaCcVisa';
import { FaRegCreditCard } from '@react-icons/all-files/fa6/FaRegCreditCard';
import { SiAmericanexpress } from '@react-icons/all-files/si/SiAmericanexpress';

import { CardBrand } from 'types';

export const getCardBrandIcon = (brand?: CardBrand) => {
  switch (brand) {
    case 'Visa':
      return FaCcVisa;
    case 'MasterCard':
      return FaCcMastercard;
    case 'American Express':
      return SiAmericanexpress;
    case 'Discover':
      return FaCcDiscover;
    case 'JCB':
      return FaCcJcb;
    case 'Diners Club':
      return FaCcDinersClub;
    default:
      return FaRegCreditCard;
  }
};
