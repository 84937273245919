import { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

export const withElements =
  <T extends object>(Component: FC<T>) =>
  (props: T) => (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
