import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { TbReload } from '@react-icons/all-files/tb/TbReload';

interface Pagination extends PropsWithChildren {
  callback: () => void;
  bottomOffSet: number;
  hasLoadMoreButton?: boolean;
}

export const Pagination: FC<Pagination> = ({ children, callback, bottomOffSet, hasLoadMoreButton }) => {
  const refCb = useRef<() => void>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    refCb.current = callback;
  }, [callback]);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.intersectionRatio > 0) {
          refCb.current?.();
        }
      });

      observer.observe(ref.current);

      return () => {
        observer?.disconnect();
      };
    }
  }, []);

  const anchorStyle = {
    bottom: bottomOffSet
  };

  return (
    <div className='position-relative'>
      {children}
      <div ref={ref} className='position-absolute w-100' style={anchorStyle} />
      {hasLoadMoreButton && (
        <div className='w-100 d-flex justify-content-center'>
          <TbReload size={30} onClick={callback} />
        </div>
      )}
    </div>
  );
};
