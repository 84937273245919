import { FC } from 'react';

import { Listing, ListingCondition, PurchaseRequest } from 'types';

import { RequestsTab } from './RequestsTab';

type SplitData = {
  [ListingCondition.Unworn]: (Listing | PurchaseRequest)[];
  [ListingCondition.Preowned]: (Listing | PurchaseRequest)[];
};
type TabSelectorProps = {
  selectedTab: ListingCondition;
  setSelectedTab: (tab: ListingCondition) => void;
  splitData: SplitData;
};

export const TabSelector: FC<TabSelectorProps> = ({ selectedTab, setSelectedTab, splitData }) => (
  <>
    <div className='border-top w-100' />
    <div className='w-100 position-relative'>
      <div className='d-flex w-100 px-4'>
        <RequestsTab
          onClick={() => setSelectedTab(ListingCondition.Unworn)}
          isSelected={selectedTab === ListingCondition.Unworn}
          title='Unworn'
          count={splitData[ListingCondition.Unworn].length}
        />
        <RequestsTab
          onClick={() => setSelectedTab(ListingCondition.Preowned)}
          isSelected={selectedTab === ListingCondition.Preowned}
          title='Pre-owned'
          count={splitData[ListingCondition.Preowned].length}
        />
      </div>
      <div className='border-top w-100' />
    </div>
  </>
);
