import { FC, useEffect, useState } from 'react';
import { FaBuildingColumns } from '@react-icons/all-files/fa6/FaBuildingColumns';
import { FaCircleCheck } from '@react-icons/all-files/fa6/FaCircleCheck';
import { FaPlus } from '@react-icons/all-files/fa6/FaPlus';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { PaymentMethods } from 'consts/payment_methods';
import { useViewport } from 'context/viewport';
import { BuyerProfile, PaymentMethod } from 'types';

import { CardPayment } from './CardPayment';
import { isSelectedPaymentMethod, paymentMethodTestIdPrefix, paymentStyles } from './helpers';

type PaymentListModalContentProps = {
  buyer: BuyerProfile;
  allowedPaymentTypes: string[];
  onClose: () => void;
  selectedPaymentMethod: PaymentMethod | null;
  setSelectedPaymentMethod: (val: PaymentMethod) => void;
  setAddingPaymentMethod: (val: boolean) => void;
  isDark?: boolean;
};

export const PaymentListModalContentTestIds = {
  body: 'payment-list-modal-content-body',
  cardPaymentMethodButton: `${paymentMethodTestIdPrefix}-${PaymentMethods.Card.toLowerCase()}`
};

export const PaymentListModalContent: FC<PaymentListModalContentProps> = ({
  buyer,
  allowedPaymentTypes,
  onClose,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setAddingPaymentMethod,
  isDark
}) => {
  const [structuredPaymentMethods, setStructuredPaymentMethods] = useState<PaymentMethod[]>([]);
  const { isMobileSmall } = useViewport();
  const colWidths: [number, number, number] = isMobileSmall ? [3, 7, 2] : [2, 8, 2];

  const hasSavedCards = !!structuredPaymentMethods.find(p => p.type === PaymentMethods.Card);

  const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    onClose();
  };

  useEffect(() => {
    const formattedPaymentMethods = [];

    if (allowedPaymentTypes.includes(PaymentMethods.Card)) {
      formattedPaymentMethods.push(...buyer.billingAccount.cards.map(c => ({ type: PaymentMethods.Card, details: c })));
    }

    [PaymentMethods.Klarna, PaymentMethods.Affirm, PaymentMethods.Wire].forEach(type => {
      if (allowedPaymentTypes.includes(type)) {
        formattedPaymentMethods.push({ type });
      }
    });

    if (formattedPaymentMethods.length === 0) {
      setAddingPaymentMethod(true);
    } else {
      setStructuredPaymentMethods(formattedPaymentMethods);
    }
  }, [allowedPaymentTypes, buyer, setAddingPaymentMethod, setStructuredPaymentMethods]);

  const addNewCardButton = (
    <div role='button' onClick={() => setAddingPaymentMethod(true)} {...paymentStyles}>
      <div
        className={classNames(
          'w-100 d-flex align-items-center justify-content-center text-primary fs-14px riforma-medium',
          { 'text-turquoise': isDark }
        )}
      >
        <FaPlus size='1.25em' className='me-3' />
        <span>Add a new card</span>
      </div>
    </div>
  );

  const checkIcon = (
    <FaCircleCheck
      size='1.5em'
      className={classNames(isDark ? 'text-turquoise' : 'text-success', `col-${colWidths[2]}`)}
    />
  );

  return (
    <div
      className={classNames(
        'riforma-regular d-flex flex-column',
        isMobileSmall ? 'justify-content-start' : 'justify-content-center',
        isDark ? 'text-turquoise' : 'text-primary'
      )}
      data-testid={PaymentListModalContentTestIds.body}
    >
      <div className='overflow-auto hide_scroll_bar' style={{ maxHeight: 'auto', height: 'auto' }}>
        {!hasSavedCards && addNewCardButton}
        {structuredPaymentMethods.map((p, idx) => (
          <div
            role='button'
            key={`address-${idx}`}
            onClick={() => onSelectPaymentMethod(p)}
            {...paymentStyles}
            data-testid={`${paymentMethodTestIdPrefix}-${p.type.toLowerCase()}`}
          >
            {p.type === PaymentMethods.Card && (
              <CardPayment
                checkIcon={checkIcon}
                colWidths={colWidths}
                isSelectedPaymentMethod={paymentMethod => isSelectedPaymentMethod(paymentMethod, selectedPaymentMethod)}
                paymentMethod={p}
              />
            )}
            {p.type === PaymentMethods.Klarna && (
              <div
                className={`row g-0 w-100 align-items-center ${
                  isSelectedPaymentMethod(p, selectedPaymentMethod) ? 'text-success' : 'text-primary'
                } fs-12px`}
              >
                <div className={`col-${colWidths[0]}`}>
                  <CloudinaryImage src='klarna_logo.png' alt='Klarna Logo' width={50} height={20} />
                </div>
                <div className={`col-${colWidths[1]}`}>Klarna</div>
                {isSelectedPaymentMethod(p, selectedPaymentMethod) && checkIcon}
              </div>
            )}
            {p.type === PaymentMethods.Affirm && (
              <div
                className={`row g-0 w-100 align-items-center ${
                  isSelectedPaymentMethod(p, selectedPaymentMethod) ? 'text-success' : 'text-primary'
                } fs-12px`}
              >
                <div className={`col-${colWidths[0]}`}>
                  <CloudinaryImage
                    src='affirm_black_logo-transparent_bg.png'
                    alt='Affirm Logo'
                    width={50}
                    height={20}
                  />
                </div>
                <div className={`col-${colWidths[1]}`}>
                  <span>Affirm</span>
                </div>
                {isSelectedPaymentMethod(p, selectedPaymentMethod) && checkIcon}
              </div>
            )}
            {p.type === PaymentMethods.Wire && (
              <div className='row g-0 w-100 align-items-center text-primary fs-12px'>
                <div className={`col-${colWidths[0]} d-inline-flex`}>
                  <div
                    className='rounded-1'
                    style={{
                      backgroundColor: isSelectedPaymentMethod(p, selectedPaymentMethod) ? '#eef3ef' : '#ebeae7',
                      padding: '0.75rem'
                    }}
                  >
                    <FaBuildingColumns
                      className={isSelectedPaymentMethod(p, selectedPaymentMethod) ? 'text-success' : 'text-secondary'}
                      size='1.5em'
                    />
                  </div>
                </div>
                <div className={`col-${colWidths[1]}`}>Wire (Save 2.9%)</div>
                {isSelectedPaymentMethod(p, selectedPaymentMethod) && checkIcon}
              </div>
            )}
          </div>
        ))}
        {hasSavedCards && addNewCardButton}
      </div>
    </div>
  );
};
