import { FC, ReactNode } from 'react';
import { HiOutlineDotsVertical } from '@react-icons/all-files/hi/HiOutlineDotsVertical';
import dayjs from 'dayjs';

import { PurchaseRequest } from 'types';

import { Countdown } from './Countdown';

type InfoSectionProps = {
  request: PurchaseRequest;
  onOptionsClick: () => void;
};

type InfoItem = {
  title: 'CONDITION' | 'YEARS' | 'EXPIRES IN' | 'EXPIRED ON';
  value: ReactNode;
  isSmall?: boolean;
};

export const InfoSection: FC<InfoSectionProps> = ({ request, onOptionsClick }) => {
  const infoData: InfoItem[] = [
    {
      title: 'CONDITION',
      value: request.unwornRestriction === 'REQUIRE' ? 'UNWORN ONLY' : 'PREOWNED'
    },
    {
      title: 'YEARS',
      value: request.yearRestriction ? `${request.yearRestriction}+` : 'ALL OKAY',
      isSmall: true
    }
  ];

  if (request.status === 'LIVE') {
    infoData.push({
      title: 'EXPIRES IN',
      value: <Countdown expiration={request.expiration} />
    });
  } else {
    infoData.push({
      title: 'EXPIRED ON',
      value: <span>{dayjs(request.expiration).format('MM/DD/YY')}</span>
    });
  }

  return (
    <div className='d-flex align-items-center pb-3 border-bottom fs-12px mx-4'>
      {infoData.map(({ title, value, isSmall }) => (
        <div className='letter-spacing-006' style={{ flex: isSmall ? 2 : 3 }} key={title}>
          <div className='opacity-60'>{title}</div>
          <div className='riforma-regular'>{value}</div>
        </div>
      ))}
      <div role='button' onClick={onOptionsClick} className='d-flex align-items-center px-3 h-100'>
        <HiOutlineDotsVertical size={22} />
      </div>
    </div>
  );
};
