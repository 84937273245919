import { FC, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { NewCardModalContent } from 'components/checkout/payment/NewCardModalContent';
import { PaymentListModalContent } from 'components/checkout/payment/PaymentListModalContent';
import { Slideout } from 'components/design-system/Slideout';
import { useBezelUser } from 'context/bezelUser';
import { stripePromise } from 'lib/stripe';
import { BuyerProfile, PaymentMethod, PaymentTransaction } from 'types';

export const PaymentModalTestIds = {
  container: 'payment-modal-container'
};

type PaymentModalProps = {
  allowedPaymentTypes: string[];
  isOpen: boolean;
  onClose: () => void;
  setSelectedPaymentMethod: (val: PaymentMethod) => void;
  selectedPaymentMethod: PaymentMethod | null;
  postSubmitNewCard: (card: PaymentTransaction['paymentCard']) => void;
  buyer: BuyerProfile;
  isDark?: boolean;
};

export const PaymentModal: FC<PaymentModalProps> = props => {
  const { isOpen, onClose, postSubmitNewCard, isDark } = props;
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);

  const { user } = useBezelUser();
  const buyer = user?.buyerProfile;

  const onCloseModal = () => {
    onClose();
    setAddingPaymentMethod(false);
  };

  return (
    <Slideout
      isDark={isDark}
      show={isOpen}
      closeModal={onCloseModal}
      aria-labelledby='contained-modal-title-vcenter'
      data-testid={PaymentModalTestIds.container}
      title={addingPaymentMethod ? 'Add New Card' : 'Payment Methods'}
    >
      <>
        {!addingPaymentMethod && <PaymentListModalContent {...props} setAddingPaymentMethod={setAddingPaymentMethod} />}
        {addingPaymentMethod && buyer && (
          <Elements stripe={stripePromise}>
            <NewCardModalContent
              isDark={isDark}
              billingAccountId={buyer.billingAccount.id}
              onClose={onCloseModal}
              postSubmitNewCard={postSubmitNewCard}
            />
          </Elements>
        )}
      </>
    </Slideout>
  );
};
