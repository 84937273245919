import classNames from 'classnames';

import { Pagination } from 'components/common/Pagination';
import { useViewport } from 'context/viewport';
import { Model } from 'types';

import { ModelSelectorCard } from './ModelSelectorCard';
import { ModelSelectorEmptyState } from './ModelSelectorEmptyState';
import { ModelSelectorLoader } from './ModelSelectorLoader';
import { useGetListingModels } from './useGetListingModels';

const containerStyle = {
  maxWidth: '704px'
};

type ModelSelectorProps = {
  onSelectModel: (model: Model) => void;
  renderTitle?: (onUpdateQuery: (val: string) => void) => React.ReactNode;
  itemsPerRow?: number;
};

export const ModelSelector: React.FC<ModelSelectorProps> = ({ onSelectModel, renderTitle, itemsPerRow = 3 }) => {
  const { models, paginationCallback, onUpdateQuery, isEmpty, isLoading } = useGetListingModels();
  const { isTabletOrMobile } = useViewport();

  return (
    <div className='mx-auto' style={containerStyle}>
      {renderTitle && renderTitle(onUpdateQuery)}
      {isLoading && <ModelSelectorLoader itemsPerRow={itemsPerRow} isTabletOrMobile={isTabletOrMobile} />}
      {!isLoading &&
        (isEmpty ? (
          <ModelSelectorEmptyState />
        ) : (
          <Pagination callback={paginationCallback} bottomOffSet={200}>
            <div className='row p-0 m-0 position-relative w-100'>
              {models?.map((model, i) => (
                <div
                  key={model.id}
                  className={classNames(
                    'col-6 p-1',
                    { 'col-lg-4 p-lg-2': itemsPerRow === 3 },
                    isTabletOrMobile && (i % 2 === 0 ? 'ps-0' : 'pe-0')
                  )}
                  onClick={() => onSelectModel(model)}
                >
                  <ModelSelectorCard model={model} />
                </div>
              ))}
            </div>
          </Pagination>
        ))}
    </div>
  );
};
