import { useEffect, useRef, useState } from 'react';
import { CurrencyInputProps, default as CurrencyInputComponent, formatValue } from 'react-currency-input-field';
import classNames from 'classnames';

import styles from './CurrencyInput.module.scss';

export const CurrencyInputTestIds = {
  input: 'currency-input-input'
};

interface InputProps extends CurrencyInputProps {
  value?: string | number;
  isResponsive?: boolean;
  autoFocus?: boolean;
  isError?: boolean;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}

export const CurrencyInput: React.FC<InputProps> = ({
  value,
  placeholder,
  className,
  isResponsive,
  autoFocus = false,
  isError,
  onBlur,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [size, setSize] = useState<number>(1);

  useEffect(() => {
    const formattedValue = formatValue({
      value: value?.toString() || '',
      groupSeparator: ',',
      decimalSeparator: '.'
    });

    setSize(formattedValue.length || 1);
  }, [value]);

  useEffect(() => {
    // regular autoFocus prop does not on slideouts
    if (autoFocus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <CurrencyInputComponent
        ref={inputRef}
        data-testid={CurrencyInputTestIds.input}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={classNames(className, { 'text-danger': isError }, styles.input, isError && styles['is-error'])}
        size={isResponsive ? size : undefined}
        allowDecimals={false}
        allowNegativeValue={false}
        onBlur={onBlur}
        maxLength={9}
        {...rest}
      />
    </div>
  );
};
