import { Analytics } from 'context/analytics';
import { Model } from 'types';

import { fireTrackEvent } from './helpers';

type SegmentPayload = {
  brandName: string;
  modelId: number;
  modelName: string;
  referenceNumber: string;
  imageUrl: string;
};

export const logPurchaseRequestStarted = (analytics: Analytics, model?: Model) => {
  if (!model) {
    return;
  }

  const payload: SegmentPayload = {
    modelId: model.id,
    modelName: model.displayName,
    referenceNumber: model.referenceNumber,
    brandName: model.brand?.displayName,
    imageUrl: model.images?.[0]?.optimizedUrl || ''
  };

  fireTrackEvent(analytics, 'Started Purchase Request', payload);
};
